.timeline {
    border-left: 1px solid #23AAE1;
    position: relative;
    list-style: none;
}

.timeline .timeline-item {
    position: relative;
}

.timeline .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .timeline-item:after {
    background-color: #23AAE1;
    left: -38px;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
}

.LabelMulti{
    padding-left: 25px;
    padding-top: 10px;
    font-weight: bolder;
}

.TableTicket{
    background: rgb(250, 250, 250);
    padding: 5px 10px;
    border-inline-start: 1px solid rgba(0, 0, 0, 0.176);
    margin-left: -19px !important;
    margin-right: -30px !important;
}

.spinnerSS{
    margin-top: -10px;
    margin-left: 1px;
    margin-right: 1px;
    height: 150px;
}

.timeoutSpinner {
    animation-delay: 2s;
    animation-duration: 1ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: spinnerDisplay;
}

@keyframes spinnerDisplay {
    to {
       display: none;
    }
}

.CardTargetWorkTracking{
    border-radius: 5px 5px 0 0  !important;
    border-bottom: 0  !important;
    border-top:0;
    margin-left: -1px;
    margin-right: -1px;
}

.StarButton{
    background: linear-gradient(180deg,#135e7e,#103b5b 35%,#015b91);
    border: none;
    border-radius: 3px 0 0 3px;
    box-shadow: 0 5px 16px 0 rgba(0,0,0,.35);
    color: yellow !important;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 15px;
    position: fixed;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .4s cubic-bezier(.55,0,.1,1);
    z-index: 10000;
}
@media print {
    @page { margin: 0cm }
    .theme-header,.theme-footer,#modal,#tooltip {
        display: none;
    }

    #sidebarCol,#FavoButton,#ChangeDateWTM,#printButton {
        display: none;
    }
    .ticketsystem{
        width: 100%;
        height: 100%;
    }
    .ContentWrapper{
        padding:0  !important;
        margin:0  !important;
        background:white !important;
    }

    .ContentWrapper:has(.ticketsystem){
        background: white !important;
    }

    body{
        background:white;
    }
}

.trCell{
    max-height: 40px  !important;
    padding: 0px  !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px  !important;
}

.arrowBottom:after {
    content:'';
    position: absolute;
    left: -33px;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 15px solid #343232;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.working .form-check-input:checked {
    background-color: #008000;
    border-color: #008000;
}

.vacation .form-check-input:checked {
    background-color: #FBB17C;
    border-color: #FBB17C;
}

.sick .form-check-input:checked {
    background-color: #666666;
    border-color: #666666;
}

.unapologetic .form-check-input:checked {
    background-color: #8B0000;
    border-color: #8B0000;
}

.absent .form-check-input:checked {
    background-color: #00008B;
    border-color: #00008B;
}

.training .form-check-input:checked {
    background-color: #8800FF;
    border-color: #8800FF;
}

.LegendeColorHolder{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 10px;
}
