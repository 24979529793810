/*
Material Icon and Symbol Library
 */
@import url('material-icons/iconfont/material-icons.css');
@import url('material-symbols');

/*
Bootstrap 4
 */
@import url("css/bootstrap.min.css");

/*
Bootstrap 5
 */
@import url("css/bootstrap5.min.css.scss");

/*
Base css with all general settings
 */
@import url("css/base.css");

/*
Sidebar css with all style to sidebar
 */
@import url("css/sidebar.css");

/*
Footer css with all style to footer
 */
@import url("css/footer.css");

/*
Modal css with all style to modal
 */
@import url("css/modal.css");

/*
React Page CSS with all style to ract-page
 */
@import url("css/react-page.css");

/*
Scrollbar CSS with all style to scrollbar
 */
@import url("css/scrollbar.css");

/*
Tooltip CSS with all style to tooltip
 */
@import url("css/tooltip.css");

/*
Modul Masonry CSS with all style to masonry
 */
@import url("css/module/masonry.css");

/*
Modul Hexagon CSS with all style to hexagon
 */
@import url("css/module/hexagon.css");

/*
Unsortiertes CSS Muss noch zugeordnet werden
 */
@import url("css/unsort.css");

/* Ampelsystem */
#TablePaymentHistory{
    --bs-table-bg:transparent  !important;
}

.PaymentHistroyRed {
    background-color: rgb(178 34 34 / 80%) !important;
}

.PaymentHistroyRed td{
    color:white  !important;
    font-weight: bold;
}

.PaymentHistroyGreen{
    background-color: rgb(0 128 0 / 80%) !important;
}

.PaymentHistroyGreen td{
    color:white  !important;
    font-weight: bold;
}

.PaymentHistroyYellow{
    background-color: rgb(255 255 153 / 80%) !important;
}

.PaymentHistroyYellow td{
    color:black  !important;
    font-weight: bold;
}

/* Ende Ampelsystem */


.adjustWidth{
    max-width: 20px;
}


.css-dip3t8{
    overflow-y: hidden !important;
}
